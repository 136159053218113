let routes = [
  {
    path: '',
    redirect: { name: 'BoLogin' },
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/BoLogin.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },
  {
    path: 'dashboard',
    name: 'BoDashboard',
    component: () => import('@/views/BoDashboard.vue'),
    meta: {
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'management-dashboard',
    name: 'BoDashboardManagement',
    component: () => import('@/views/BoDashboardManagement.vue'),
    meta: {
      menu: 'BoDashboardManagement',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'registrants',
    name: 'BoRegistrants',
    component: () => import('@/views/BoRegistrants.vue'),
    meta: {
      menu: 'BoRegistrants',
      pageTitle: 'Registrants',
      objName: 'Registrant',
    },
  },
  {
    path: 'registrants/:id',
    name: 'BoRegistrantDetails',
    component: () => import('@/views/BoRegistrantDetails.vue'),
    meta: {
      menu: 'BoRegistrantDetails',
      pageTitle: 'Registrants',
      objName: 'Registrant',
    },
  },
  {
    path: 'traveler-family',
    name: 'BoTravelerFamily',
    component: () => import('@/views/BoTravelerFamily.vue'),
    meta: {
      menu: 'BoTravelerFamily',
      pageTitle: 'Traveler & Family Tree',
      objName: 'Registrant',
    },
  },
  {
    path: 'traveler-family/:id',
    name: 'BoTravelerFamilyDetails',
    component: () => import('@/views/BoTravelerFamilyDetails.vue'),
    meta: {
      menu: 'BoTravelerFamilyDetails',
      pageTitle: 'Traveler & Family Tree',
      objName: 'Registrant',
    },
  },
  {
    path: 'campaign',
    name: 'BoCampaign',
    component: () => import('@/views/BoCampaign.vue'),
    meta: {
      menu: 'BoCampaign',
      pageTitle: 'Campaigns',
      objName: 'Campaign',
    },
  },
  {
    path: 'campaign/:type/:id',
    name: 'BoCampaignForm',
    component: () => import('@/views/BoCampaignForm.vue'),
    meta: {
      menu: 'BoCampaignForm',
      pageTitle: 'Campaigns',
      objName: 'Campaign',
    },
  },
  {
    path: 'campaign/:id',
    name: 'BoCampaignDetails',
    component: () => import('@/views/BoCampaignDetails.vue'),
    meta: {
      menu: 'BoCampaignDetails',
      pageTitle: 'Campaigns',
      objName: 'Campaign',
    },
  },
  {
    path: 'campaign-transportation/:id',
    name: 'BoCampaignFleet',
    component: () => import('@/views/BoCampaignFleet.vue'),
    meta: {
      menu: 'BoCampaignFleet',
      pageTitle: 'Campaigns',
      objName: 'Campaign',
    },
  },
  {
    path: 'discount',
    name: 'BoDiscount',
    component: () => import('@/views/BoDiscount.vue'),
    meta: { 
      menu: 'BoDiscount',
    }
  },
  {
    path: 'discount/:type/:id?',
    name: 'BoDiscountForm',
    component: () => import('@/views/BoDiscountForm.vue'),
    meta: {
      menu: 'BoDiscountForm',
    },
  },
  {
    path: 'booking',
    name: 'BoBooking',
    component: () => import('@/views/BoBooking.vue'),
    meta: {
      menu: 'BoBooking',
      pageTitle: 'Booking List',
      objName: 'Booking List',
    }
  },
  {
    path: 'booking/details/:id',
    name: 'BoBookingDetails',
    component: () => import('@/views/BoBookingDetails.vue'),
    meta: {
      menu: 'BoBookingDetails',
      pageTitle: 'Booking Details',
      objName: 'Booking Details',
    }
  },
  {
    path: 'pending-transaction',
    name: 'BoPendingTrx',
    component: () => import('@/views/BoPendingTrx.vue'),
    meta: {
      menu: 'BoPendingTrx',
      pageTitle: 'Pending Transaction',
      objName: 'Pending Transaction',
    }
  },
  {
    path: 'inquiry',
    name: 'BoInquiry',
    component: () => import('@/views/BoInquiry.vue'),
    meta: {
      menu: 'BoInquiry',
      pageTitle: 'Inquiry',
      objName: 'Inquiry',
    }
  },
  {
    path: 'inquiry/:type',
    name: 'BoInquiryForm',
    component: () => import('@/views/BoInquiryForm.vue'),
  },
  {
    path: 'inquiry/details/:id',
    name: 'BoInquiryDetails',
    component: () => import('@/views/BoInquiryDetails.vue'),
    meta: {
      menu: 'BoInquiryDetails',
      pageTitle: 'Inquiry Details',
      objName: 'Inquiry',
    }
  },
  {
    path: 'blacklist',
    name: 'BoBlacklist',
    component: () => import('@/views/BoBlacklist.vue'),
    meta: {
      menu: 'BoBlacklist',
      pageTitle: 'Blacklist',
      objName: 'Blacklist',
    }
  },
  {
    path: 'blacklist',
    name: 'BoBlacklistDetails',
    component: () => import('@/views/BoBlacklistDetails.vue'),
    meta: {
      menu: 'BoBlacklistDetails',
      pageTitle: 'Blacklist',
      objName: 'Blacklist',
    }
  },
  {
    path: 'waiting-list',
    name: 'BoWaitingList',
    component: () => import('@/views/BoWaitingList.vue'),
    meta: {
      menu: 'BoWaitingList',
      pageTitle: 'Seat Assignment',
      objName: 'Seat',
    }
  },
  {
    path: 'tracking',
    name: 'BoTracking',
    component: () => import('@/views/BoTracking.vue'),
    meta: {
      menu: 'BoTracking',
    }
  },
  {
    path: 'vip-codes',
    name: 'BoVipCode',
    component: () => import('@/views/BoVipCode.vue'),
    meta: {
      menu: 'BoVipCode',
    }
  },
  {
    path: 'vip-codes/:type/:id?',
    name: 'BoVipCodeForm',
    component: () => import('@/views/BoVipCodeForm.vue'),
    meta: {
      menu: 'BoVipCodeForm',
    }
  },
  {
    path: 'contact',
    name: 'BoContact',
    component: () => import('@/views/BoContact.vue'),
    meta: {
      menu: 'BoContact',
    },
  },
  {
    path: 'inbox',
    name: 'BoChatInbox',
    component: () => import('@/views/BoChatInbox.vue'),
    meta: {
      menu: 'BoChatInbox',
    },
  },
  {
    path: 'live-chat/:id',
    name: 'BoChatting',
    component: () => import('@/views/BoChatting.vue'),
    meta: {
      menu: 'BoChatting',
    },
  },
  {
    path: 'ticketing',
    name: 'BoTicketing',
    component: () => import('@/views/BoTicketing.vue'),
    meta: {
      menu: 'BoTicketing',
    }
  },
  {
    path: 'message',
    name: 'BoMessage',
    component: () => import('@/views/BoMessage.vue'),
    meta: {
      menu: 'BoMessage',
    }
  },
  {
    path: 'instances',
    name: 'BoInstances',
    component: () => import('@/views/BoInstances.vue'),
    meta: {
      menu: 'BoInstances',
    }
  },
  {
    path: 'instances/:type/:id?',
    name: 'BoInstancesForm',
    component: () => import('@/views/BoInstancesForm.vue'),
    meta: {
      menu: 'BoInstancesForm',
    }
  },
  {
    path: 'message/:type/:id?',
    name: 'BoMessageForm',
    component: () => import('@/views/BoMessageForm.vue'),
    meta: {
      menu: 'BoMessageForm',
    }
  },
  {
    path: 'master/transportation/:transport',
    name: 'BoMTransportation',
    component: () => import('@/views/BoMTransportation.vue'),
    meta: {
      menu: 'BoMTransportation',
      pageTitle: 'Transportation',
      objName: 'Transportation'
    }
  },
  {
    path: 'master/transportation/:transport/:type/:id?',
    name: 'BoMTransportationForm',
    component: () => import('@/views/BoMTransportationForm.vue'),
    meta: {
      menu: 'BoMTransportationForm',
      pageTitle: 'Transportation',
      objName: 'Transportation'
    }
  },
  {
    path: 'master/transportation-stops/:stop',
    name: 'BoMStops',
    component: () => import('@/views/BoMStops.vue'),
    meta: {
      menu: 'BoMStops',
      pageTitle: 'Transportation Stop',
      objName: 'Transportation Stop'
    }
  },
  {
    path: 'master/transportation-stops/:stop/:type/:id?',
    name: 'BoMStopsForm',
    component: () => import('@/views/BoMStopsForm.vue'),
    meta: {
      menu: 'BoMStopsForm',
      pageTitle: 'Transportation Stop',
      objName: 'Transportation Stop'
    }
  },
  {
    path: 'master/transportation-operators/:operator',
    name: 'BoMOperators',
    component: () => import('@/views/BoMOperators.vue'),
    meta: {
      menu: 'BoMOperators',
      pageTitle: 'Transportation Operator',
      objName: 'Transportation Operator'
    }
  },
  {
    path: 'master/transportation-operators/:operator/:type/:id?',
    name: 'BoMOperatorsForm',
    component: () => import('@/views/BoMOperatorsForm.vue'),
    meta: {
      menu: 'BoMOperatorsForm',
      pageTitle: 'Transportation Operator',
      objName: 'Transportation Operator'
    }
  },
  {
    path: 'master/transportation-class',
    name: 'BoMTransportationClass',
    component: () => import('@/views/BoMTransportationClass.vue'),
    meta: {
      menu: 'BoMTransportationClass',
      pageTitle: 'Passenger Category',
      objName: 'Passenger Category'
    },
  },
  {
    path: 'master/transportation-class/:type/:id?',
    name: 'BoMTransportationClassForm',
    component: () => import('@/views/BoMTransportationClassForm.vue'),
    meta: {
      menu: 'BoMTransportationClassForm',
      pageTitle: 'Passenger Category',
      objName: 'Passenger Category'
    },
  },
  {
    path: 'master/passenger-category',
    name: 'BoMPassengerCategory',
    component: () => import('@/views/BoMPassengerCategory.vue'),
    meta: {
      menu: 'BoMPassengerCategory',
      pageTitle: 'Passenger Category',
      objName: 'Passenger Category'
    },
  },
  {
    path: 'master/passenger-category/:type/:id?',
    name: 'BoMPassengerCategoryForm',
    component: () => import('@/views/BoMPassengerCategoryForm.vue'),
    meta: {
      menu: 'BoMPassengerCategoryForm',
      pageTitle: 'Passenger Category',
      objName: 'Passenger Category'
    },
  },
  {
    path: 'master/area',
    name: 'BoMArea',
    component: () => import('@/views/BoMArea.vue'),
    meta: {
      menu: 'BoMArea',
      pageTitle: 'Area',
      objName: 'Area'
    },
  },
  {
    path: 'master/area/:type/:id?',
    name: 'BoMAreaForm',
    component: () => import('@/views/BoMAreaForm.vue'),
    meta: {
      menu: 'BoMAreaForm',
      pageTitle: 'Area',
      objName: 'Area'
    },
  },
  {
    path: 'master/template',
    name: 'BoMTemplate',
    component: () => import('@/views/BoMTemplate.vue'),
    meta: {
      menu: 'BoMTemplate',
      pageTitle: 'Template',
      objName: 'Template'
    },
  },
  {
    path: 'master/template/:type/:id?',
    name: 'BoMTemplateForm',
    component: () => import('@/views/BoMTemplateForm.vue'),
    meta: {
      menu: 'BoMTemplateForm',
      pageTitle: 'Template',
      objName: 'Template'
    },
  },
  {
    path: 'app-configuration',
    name: 'BoAppConfiguration',
    component: () => import('@/views/BoAppConfiguration.vue'),
    meta: {
      pageTitle: 'App Configuration',
    },
  },
  {
    path: 'dashboard-snapshot',
    name: 'BoDashboardSnaphot',
    component: () => import('@/views/BoDashboardSnapshot.vue'),
    meta: {
      pageTitle: 'Dashboard Snapshot',
    },
  },
  {
    path: 'content-management/home',
    name: 'BoCmsHome',
    component: () => import('@/views/BoCmsHome.vue'),
    meta: {
      menu: 'BoCmsHome',
    },
  },
  {
    path: 'content-management/registration',
    name: 'BoCmsRegistration',
    component: () => import('@/views/BoCmsRegistration.vue'),
    meta: {
      menu: 'BoCmsRegistration',
    },
  },
  {
    path: 'content-management/partners',
    name: 'BoCmsPartners',
    component: () => import('@/views/BoCmsPartners.vue'),
    meta: {
      menu: 'BoCmsPartners',
    },
  },
  {
    path: 'content-management/faq',
    name: 'BoCmsFaq',
    component: () => import('@/views/BoCmsFaq.vue'),
    meta: {
      menu: 'BoCmsFaq',
    },
  },
  {
    path: 'content-management/faq/:type/:id?',
    name: 'BoCmsFaqForm',
    component: () => import('@/views/BoCmsFaqForm.vue'),
    meta: {
      menu: 'BoCmsFaqForm',
    },
  },
  {
    path: 'content-management/faq-category',
    name: 'BoCmsFaqCategory',
    component: () => import('@/views/BoCmsFaqCategory.vue'),
    meta: {
      menu: 'BoCmsFaqCategory',
    },
  },
  {
    path: 'content-management/legal',
    name: 'BoCmsLegal',
    component: () => import('@/views/BoCmsLegal.vue'),
    meta: {
      menu: 'BoCmsLegal',
    },
  },
  {
    path: 'content-management/legal/:type',
    name: 'BoCmsLegalForm',
    component: () => import('@/views/BoCmsLegalForm.vue'),
    meta: {
      menu: 'BoCmsLegalForm',
    },
  },
]
export default routes