import Vue from 'vue'
import Vuex from 'vuex'

import backend from './backend'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    backend,
    // frontend
  }
})
